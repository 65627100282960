.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#auth-wrapper-custom {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: 100svh;
  font-family: "Noto Sans JP", sans-serif;
}

#auth-wrapper-custom .amplify-field__show-password {
  margin: 0;
}

#auth-wrapper-custom .amplify-button--fullwidth {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: 52px;
  line-height: 18px;
  margin: 0 auto;
  width: 290px;
}

#auth-wrapper-custom .amplify-button--primary {
  background: #67c6dd;
  color: #fff;
}

/* figma に定義されていないので一旦amplify-uiと同じ設定 */
#auth-wrapper-custom .amplify-button--primary.amplify-button--disabled {
  background: #eff0f0;
  color: #89949f;
}

#auth-wrapper-custom .amplify-tabs {
  display: none !important;
}

#auth-wrapper-custom [data-amplify-authenticator] {
  margin: auto;
  padding: 0 20px;
  width: 100%;
  max-width: 500px;
}

#auth-wrapper-custom [data-amplify-authenticator] [data-amplify-router] {
  border-style: none !important;
  box-shadow: none !important;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem 0 2rem 0;
}

#auth-wrapper-custom .amplify-input {
  height: 36px;
}

#auth-wrapper-custom .amplify-label {
  text-align: left !important;
}

#auth-wrapper-custom .amplify-field {
  gap: 4px;
}

#auth-wrapper-custom .amplify-field .amplify-label {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}

#auth-wrapper-custom .amplify-checkboxfield {
  font-size: 14px;
  margin: auto;
}

#auth-wrapper-custom [data-amplify-authenticator-signin] {
  margin-bottom: 20px;
}

#auth-wrapper-custom
  [data-amplify-authenticator-signin]
  .amplify-button--fullwidth {
  margin-top: 24px;
}

#auth-wrapper-custom [data-amplify-authenticator] [data-amplify-form] {
  padding: initial;
}

#auth-wrapper-custom [data-amplify-container] {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

#auth-wrapper-custom .logo{
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  align-items: center;
  flex-direction: column;
}

#auth-wrapper-custom fieldset {
  border: none;
}
